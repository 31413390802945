import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from '../NavigationItem/NavigationItem';

const NavigationItems = (props) => {
    return (
        <ul className={classes.NavigationItems}>

            <NavigationItem link="/" classElement="Home"> Home </NavigationItem>

            <NavigationItem link="/skills" classElement="Skills"> Compétences  </NavigationItem>

            <NavigationItem link="/projects" classElement="Projects"> Projects </NavigationItem>

            <NavigationItem link="/langues" classElement="Langues"> Langues </NavigationItem>

            <NavigationItem link="/contact" classElement="Contact"> Contact </NavigationItem>

        </ul>
    );
};

export default NavigationItems;
