import React from 'react';
import Logo from '../../components/Logo/Logo';
import DrawerToggle from '../DrawerToggle/DrawerToggle';

import classes from './ToolBar.module.css';
import NavigationItems from '../NavigationItems/NavigationItems';

const ToolBar = (props) => {
    return (
        <header className={classes.ToolBar} >
            <DrawerToggle clicked={props.drawerToggleClicked}/>
            <div className={classes.Logo}>
                <Logo />
            </div>
            <nav className={classes.DeskTopOnly}>
                <NavigationItems
                />
            </nav>
        </header>
    );
};

export default ToolBar;
