import React from 'react';

import classes from './Spinner.module.css';

function Spinner(props) {
    return (
            <span className={classes.loader}></span>
    );
}

export default Spinner;
