import React, { useState } from 'react';
import classes from './Header.module.css';

import ToolBar from '../../Navigation/ToolBar/ToolBar';
import SideDrawer from '../../Navigation/SideDrawer/SideDrawer';

function Header() {

    const [showSideDrawer, setshowSideDrawer] = useState(false);

    const sideDrawerCloseHandler = () => {
        setshowSideDrawer (false) ;
    }

    const sideDrawerToggleHandler = () => {
         setshowSideDrawer(! showSideDrawer);
    }

    return (
        <div className={classes.header}>
            <ToolBar drawerToggleClicked={sideDrawerToggleHandler} />
            <SideDrawer
                sideDrawerClosed={sideDrawerCloseHandler}
                open={showSideDrawer}
                />
        </div>
    );
}

export default Header;
