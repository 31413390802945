import React, { Fragment } from 'react';

import './App.css';
import Home from './containers/Home/Home';
import Skills from './containers/Skills/Skills';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Langues from './containers/Langues/Langues';
import Projects from './containers/Projects/Projects';
import ProjectDetail from './containers/ProjectDetail/ProjectDetails';
import MailSuccess from './containers/MailSuccess/MailSuccess';
import Contact from './containers/Contact/Contact';

function App() {
  return (
      <Fragment>

        <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/skills" element={<Skills />} />
            <Route exact path="/projects" element={<Projects />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/langues" element={<Langues />} />
            <Route exact path="/projetdetail" element={<ProjectDetail />} />
            <Route exact path="/success" element={<MailSuccess />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer />
        </Router>
      </Fragment>
  );
}

export default App;
