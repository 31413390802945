import React from 'react';

import BurgerLogo from '../../assets/images/logo.avif';
import classes from './Logo.module.css';

const Logo = () => {
    return (
        <div className={classes.Logo} >
            <img src={BurgerLogo} alt="my Burger"/>
        </div>
    );
};

export default Logo;
