import React from 'react';
import classes from './Home.module.css';

import CVPdf from '../../assets/pdf/CV-BLANCHARD Jean Pierre.pdf';

import Button from '../../components/Button/Button';


function Home(props) {
    return (
        <div className={classes.home}>
            <div className={classes.Container}>

                <div className={classes.Names}>

                    <div className={classes.Prenom}>

                        <h1>Jean-Pierre Daniel</h1>
                    </div>

                    <div className={classes.Nom}>
                        <h1>Blanchard</h1>
                    </div>

                </div>
                <div className={classes.Title}>
                    <h1>Consultant Informatique</h1>
                </div>

                <div className={classes.Mail}>
                    <h1>gaston_1811@ymail.com</h1>
                </div>

                <div className={classes.DownloadButton}>
                    <a href={CVPdf} download="CV-BLANCHARDJeanPierre.pdf" rel="noreferrer"  target='_blank'>
                            <Button btnType="Normal Info"><span className={classes.LibelleButton}>Download CV</span></Button>
                    </a>
                </div>



            </div>


        </div>
    );
}

export default Home;
