import React from 'react';
import classes from './Skills.module.css';

function Skills(props) {
    return (
        <div className={classes.Container}>
            <div className={classes.Title}>
                <h1>Compétences</h1>
            </div>

            <div className={classes.AllSkills}>


                    <div className={classes.Domaine}>
                        <div className={classes.DomaineName}>
                            SGBD/R
                        </div>
                        <div className={classes.Skills}>
                            Oracle, Postgres, MySQL
                        </div>

                    </div>

                    <div className={classes.Domaine}>
                        <div className={classes.DomaineName}>
                            Systèmes
                        </div>
                        <div className={classes.Skills}>
                            Unix, Linux, Windows
                        </div>

                    </div>

                    <div className={classes.Domaine}>
                        <div className={classes.DomaineName}>
                            Méthodologies
                        </div>
                        <div className={classes.Skills}>
                            Méthode Merise
                        </div>

                    </div>

                    <div className={classes.Domaine}>
                        <div className={classes.DomaineName}>
                            Langages
                        </div>
                        <div className={classes.Skills}>
                        PL/SQL, SQL, SQL Plus, PHP, shell, javascript, AWK, PHP/MySQL, PL/pgSQL , Python, Ruby, C, C++, JavaScript, Typescript, HTML, CSS
                        </div>

                    </div>

                    <div className={classes.Domaine}>
                        <div className={classes.DomaineName}>
                            FrameWork
                        </div>
                        <div className={classes.Skills}>
                            React, Rails, NodeJS / Express, Django, React Native
                        </div>

                    </div>

                    <div className={classes.Domaine}>
                        <div className={classes.DomaineName}>
                            Tools
                        </div>
                        <div className={classes.Skills}>
                            Docker, Github / Gitlab, Elasticsearch, Bootstrap, Tailwind, nginx
                        </div>

                    </div>

                    <div className={classes.Domaine}>
                        <div className={classes.DomaineName}>
                            Logiciel
                        </div>
                        <div className={classes.Skills}>
                            PROGDIS, File Maker Pro, Quest Toad, SQL Developer,MS Project, SQL Server Management Studio, Ab Initio (ETL)
                        </div>

                    </div>

                    <div className={classes.Domaine}>
                        <div className={classes.DomaineName}>
                            Machine Learning
                        </div>
                        <div className={classes.Skills}>
                            R, matplotlib, numpy, pandas
                        </div>

                    </div>

            </div>
        </div>
    );
}

export default Skills;
