import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import Backdrop from '../../Navigation/Backdrop/Backdrop';
import Spinner from '../../components/Spinner/Spinner';

import classes from './Contact.module.css';

function Contact(props) {

    const [buttonClicked, setbuttonClicked] = useState(false);

    const buttonClickedHandle = () => {
        setbuttonClicked(true);
    }

    return (
        <React.Fragment>

            <Backdrop show={buttonClicked} />

            <div className={classes.Contact}>

                { buttonClicked && <Spinner/> }

                <div className={classes.Title}>
                    <h1> Contactez-Nous</h1>
                </div>

                <form action="https://formsubmit.co/741131f13c1a37af1a8e4b5f3865bbf0" method="POST">

                    <input type="hidden" name="_next" value="https://www.portfolio.jpdb.online/success" />
                    <input type="hidden" name="_captcha" value="false"></input>


                    <div className={classes.Identite}>
                        <input type="text" name="name" placeholder="Votre Nom" required />
                        <input type="email" name="email" placeholder="Votre Courriel" required />
                    </div>

                    <div className={classes.Message}>
                        <textarea placeholder="Votre Message" name="message" rows="10" required></textarea>
                    </div>

                    <div className={classes.Button}>
                        <Button
                                type="submit"
                                btnType="Normal BlackText Warning"
                                clicked={buttonClickedHandle}  >
                                Envoyer Votre Message
                        </Button>
                    </div>

                </form>


            </div>

        </React.Fragment>
    );
}

export default Contact;
