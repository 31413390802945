import React from 'react';

import classes from './Langues.module.css';

const Langues = (props) => {
    return (
        <div className={classes.Container}>
            <div className={classes.Title}>
                <h1>Langues</h1>
            </div>
            <div className={classes.AllLangues}>
                <div className={classes.SingleLangue}>

                    <div className={classes.NomLangue}>
                        Français
                    </div>

                    <div className={classes.FluencyLangue}>
                        Langue Maternelle
                    </div>

                </div>
                <div className={classes.SingleLangue}>

                    <div className={classes.NomLangue}>
                        Anglais
                    </div>

                    <div className={classes.FluencyLangue}>
                        Billingue
                    </div>

                </div>
                <div className={classes.SingleLangue}>

                    <div className={classes.NomLangue}>
                        Espagnol
                    </div>

                    <div className={classes.FluencyLangue}>
                        Excellent
                    </div>

                </div>
                <div className={classes.SingleLangue}>

                    <div className={classes.NomLangue}>
                        Italien
                    </div>

                    <div className={classes.FluencyLangue}>
                        Courant
                    </div>

                </div>
                <div className={classes.SingleLangue}>

                    <div className={classes.NomLangue}>
                        Allemand
                    </div>

                    <div className={classes.FluencyLangue}>
                        Intermédiare
                    </div>

                </div>


            </div>

        </div>
    );
}

export default Langues;
