import React from 'react';

import classes from './MailSuccess.module.css';

function MailSuccess(props) {
    return (
        <div className={classes.Container}>
            <h1>Votre Message a bien été transmis.</h1>
            <h1>Nous vous contacterons dans les plus brefs délais.</h1>
        </div>
    );
}

export default MailSuccess;
