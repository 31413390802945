import React from 'react';

import classes from './Projects.module.css';
import Project from '../../components/Project/Project';

import {ListProjets} from '../../Projects/Projects';

const Projects = () => {
     const allProjects = []
    for (const projet in ListProjets) {
        if (Object.hasOwnProperty.call(ListProjets, projet)) {
            allProjects.push({
                nom: ListProjets[projet].nom,
                imageSource: ListProjets[projet].imageSource,
                imageAlt: ListProjets[projet].imageAlt,
            })
            }
    }

    const AllProjectsDiv= allProjects.map((projet) =>
        <div key={projet.nom} className={classes.SingleProject}>
                <Project nom={projet.nom} imageSource={projet.imageSource} imageAlt={projet.imageAlt}/>
        </div>
    )

    return (
        <div className={classes.AllProjects}>
            {AllProjectsDiv}
        </div>

    );
};

export default Projects;
