import React from 'react';
import { useLocation, Link } from 'react-router-dom'

import { ListProjets } from '../../Projects/Projects';
import classes from './ProjectDetails.module.css';

const ProjectDetails = () => {

    const location = useLocation();
    const { app } = location.state;

    const allProjects = []
    for (const projet in ListProjets) {
        if (Object.hasOwnProperty.call(ListProjets, projet)) {
            allProjects.push({
                nom: ListProjets[projet].nom,
                imageSource: ListProjets[projet].imageSource,
                imageAlt: ListProjets[projet].imageAlt,
                description: ListProjets[projet].Description,
                frontend: ListProjets[projet].Frontend,
                backend: ListProjets[projet].Backend,
                url: ListProjets[projet].url,
            })
            }
    }

    const projet = allProjects.find((item) => {
        return item.nom === app
    })

    const libelleUrl = "Aller vers " + app;

    return (
        <div className={classes.ProjetDetail}>
            <div className={classes.Project}>
                <div className={classes.ProjectName}>
                    {projet.nom}
                </div>

                <div className={classes.ProjectImage}>
                    <img src={projet.imageSource} alt={projet.imageAlt} />
                </div>

                <div className={classes.Backend}>

                    <div className={classes.BackendName}>
                        Backend
                    </div>

                    <div className={classes.BackendValue}>
                        {projet.backend}
                    </div>

                </div>

                <div className={classes.Frontend}>

                    <div className={classes.FrontendName}>
                        Frontend
                    </div>

                    <div className={classes.FrontendValue}>
                        {projet.frontend}
                    </div>

                </div>

                <div className={classes.Description}>

                    <div className={classes.DescriptionName}>
                        Description
                    </div>

                    <div className={classes.DescriptionValue}>
                        {projet.description}
                    </div>

                </div>

                <div className={classes.Url}>
                <Link target="_blank" to={projet.url}  >
                    {libelleUrl}
                </Link>
            </div>

            </div>

            <div className={classes.Retour}>
                <Link to="/projects"  >
                    Retour
                </Link>
            </div>
        </div>

    );
};

export default ProjectDetails;
