import React from 'react';

import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideDrawer.module.css';
import Backdrop from '../Backdrop/Backdrop';
import Logo from '../../components/Logo/Logo';

const SideDrawer = (props) => {
    let drawerClasses
    if (props.open) { drawerClasses = [classes.SideDrawer].concat([classes.Open]).join(' ')}
    else { drawerClasses = [classes.SideDrawer].concat([classes.Close]).join(' ')}
    return (
        <React.Fragment>
            <Backdrop show={props.open} clicked={props.sideDrawerClosed} />
            <div className={drawerClasses} onClick={props.sideDrawerClosed}>
            <div className={classes.Logo}>
                    <Logo />
                </div>
                <nav>
                    <NavigationItems />
                </nav>
            </div>
        </React.Fragment>
    );
};

export default SideDrawer;
