import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Project.module.css';

const Project = (props) => {

    const appName=props.nom;
    const imageSrc= props.imageSource;
    const imageAlt =props.imageAlt;


    return (
        <div className={classes.Project}>

            <div className={classes.ProjectName}>
                {appName}
            </div>

            <div className={classes.ProjectImage}>
                <img src={imageSrc} alt={imageAlt} />
            </div>

            <div className={classes.ProjectButton}>
                <Link to="/projetdetail" state={{ app: appName }} >
                    Détails
                </Link>

            </div>
        </div>
    );
};

export default Project;
