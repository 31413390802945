export const ListProjets = {
    taskmania:  {
                    nom: 'TaskMania',
                    imageSource: 'tasksmania.png',
                    imageAlt: 'tasksmania.png',
                    Frontend:'React, bcrypt, docker',
                    Backend:'Postgres, docker, express, jsonwebtoken , nginx',
                    Description: 'Application qui permet de saisir et de supprimer des "tâches à faire" avec la date souhaitée de completion et la date réelle .',
                    url: 'https://tasksmania.jpdb.live/'
                },
    quotemaster:  {
                    nom: 'QuoteMaster',
                    imageSource: 'quotemaster.png',
                    imageAlt: 'quotemaster.png',
                    Frontend:'Rails, hotwire, docker',
                    Backend:'Postgres, docker, nginx',
                    Description: "Application qui permet de saisir, d'étiter et de supprimer des quotes, avec l'utilisation de hotwire qui permet de développer des applications single page.",
                    url: 'https://quotemaster.jpdb.live/'
                },
    burgerapp:  {
                    nom: 'BurgerApp',
                    imageSource: 'burger.jpg',
                    imageAlt: 'burger.png',
                    Frontend:'React, bcrypt, docker',
                    Backend:'Postgres, docker, express, jsonwebtoken , nginx',
                    Description: "Application qui permet de fabriquer son propre burger et ensuite de le commander. Une liste des commandes déjà passées est disponible.",
                    url: 'https://www.burger.jpdb.live/'
                },
    calendarapp:  {
                    nom: 'CalendarApp',
                    imageSource: 'calendarapp.png',
                    imageAlt: 'calendarapp.png',
                    Frontend:'Rails, Javascript, docker',
                    Backend:'Postgres, docker, nginx',
                    Description: "Application qui permet d'enregistrer son agenda. Les évenements sont modifiables directement dans les vues dayly et weekly en redimentionnant les évenements ou en faisant un drag & drop. Chaque utilisateur a son propre agenda et on ne peut créer un nouvel évenement qu'en étant connecté.",
                    url: 'https://www.calendar.jpdb.live/'
                },
    nbaapp:  {
                    nom: 'NbaApp',
                    imageSource: 'nbaapp.png',
                    imageAlt: 'nbaapp.png',
                    Frontend:'React, docker',
                    Backend:'docker, nginx, cheerios, express',
                    Description: "Application qui permet d'être à jour sur les dernières nouvelles NBA. Les news sont récupérés des sites yahoo, nba.com et beinsports grace à une api qui utilise cheerios pour faire du web scrapping.",
                    url: 'https://www.nbaapp.jpdb.live/'
                },
    weatherapp:  {
                    nom: 'WeatherApp',
                    imageSource: 'weatherapp.png',
                    imageAlt: 'weatherapp.png',
                    Frontend:'Javascript, docker',
                    Backend:'express, docker, nginx',
                    Description: "Application pour avoir les températures dans une ville souhaitée.",
                    url: 'https://www.weather.jpdb.live/'
                },
    calendrier:  {
                    nom: 'Calendrier',
                    imageSource: 'calendrier.png',
                    imageAlt: 'calendrier.png',
                    Frontend:'React, docker',
                    Backend:'docker, nginx',
                    Description: "Permet de visualiser un calendrier.",
                    url: 'https://www.calendrier.jpdb.live/'
                },

}
